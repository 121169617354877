
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContent from './components/MainContent';
import CreateFolder from './components/centers/CreateFolder';
import CardResultat from './components/centers/CardResultat';
import Contrats from './components/centers/Contrats';
import Statistiques from './components/centers/Statistiques';
import Agenda from './components/centers/Agenda';
import Entete from './components/centers/Entete';
import AddContrats from './components/centers/AddContrats';
import EditionContrat from './components/centers/EditionContrat';
import DossiersTransfere from './components/centers/DossiersTransfere';
import Archives from './components/centers/Archives';
import Corbeille from './components/centers/Corbelle';
import EditionDossiers from './components/centers/EditionDossiers';
import ListeArchivesDossiersTransfere from './components/centers/ListeArchivesDossiersTransfere';
import Login from './components/Login';
import { ThemeProvider } from './context/ContextHeader';
import { PrimeReactProvider } from 'primereact/api';
import DetailDossiersEnvoye from './components/centers/DetailDossiersEnvoye';
import LectureNotification from './components/centers/LectureNotification';
import Apercu from './components/centers/Apercu';
import 'primeicons/primeicons.css';
import Reabonnement from './components/centers/Reabonnement';
import UploadFile from './components/UploadFile';
import DetailFoldersReceive from './components/centers/DetailFoldersReceive';
import Notifications from './components/centers/Notifications';
import AddNotification from './components/centers/AddNotification';
import EditionNotification from './components/centers/EditionNotification';
import Loading from './components/centers/Loading';
import UserLoginCreate from './components/centers/UserLoginCreate';
import ListeNotificationContent from './components/centers/ListeNotificationContent';
function App() {
  return (

    <Router>

      <ThemeProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/createFolder" element={<CreateFolder />} />
          <Route path="/resultat" element={<CardResultat />} />
          <Route path="/contrats" element={<Contrats />} />
          <Route path="/statistiques" element={<Statistiques />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/entete" element={<Entete />} />
          <Route path="/addContrat" element={<AddContrats />} />
          <Route path="/modifier_contrat/:id" element={<EditionContrat />} />
          <Route path="/detail_dossier_patient/:id" element={<DetailDossiersEnvoye />} />
          <Route path="/resultat_transfere" element={<DossiersTransfere />} />
          <Route path="/archives" element={<Archives />} />
          <Route path="/corbeille" element={<Corbeille />} />
          {/* <Route path="/editionDossiers/" element={<EditionDossiers />} /> */}
          <Route path="/editionDossiers/:id" element={<EditionDossiers />} />
          <Route path="/liste_archive_dossier_transfere/" element={<ListeArchivesDossiersTransfere />} />
          <Route path="/lireNotification" element={<LectureNotification />} />
          <Route path="/apercu/:id" element={<Apercu />} />
          <Route path="/reabonnement" element={<Reabonnement />} />
          <Route path="/member_detail_folder/:id" element={<DetailFoldersReceive />} />
          <Route path="/notifications" element={<ListeNotificationContent />} />
          <Route path="/addNotifications" element={<AddNotification />} />
          <Route path="/editionNotification/:id" element={<EditionNotification />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/create-compte" element={<UserLoginCreate />} />
          <Route path="/lire-notification/:id" element={<ListeNotificationContent />} />
          
        </Routes>
      </ThemeProvider>

    </Router>
  );
}

export default App;
